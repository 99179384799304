<template>
    <!-- Instagram Section Begin -->
    <div class="instagram-photo">
        <div class="insta-item set-bg" style="background-image: url('/img/insta-1.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background-image: url('/img/insta-2.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background-image: url('/img/insta-3.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background-image: url('/img/insta-4.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background-image: url('/img/insta-5.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>
        <div class="insta-item set-bg" style="background-image: url('/img/insta-6.jpg');">
            <div class="inside-text">
                <i class="ti-instagram"></i>
                <h5><a href="#">shayna_gallery</a></h5>
            </div>
        </div>

    </div>
    <!-- Instagram Section End -->
</template>

<script>
export default {
    name: 'InstagramShayna'
}
</script>