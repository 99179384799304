import { render, staticRenderFns } from "./ShoppingcartView.vue?vue&type=template&id=09079458&scoped=true"
import script from "./ShoppingcartView.vue?vue&type=script&lang=js"
export * from "./ShoppingcartView.vue?vue&type=script&lang=js"
import style0 from "./ShoppingcartView.vue?vue&type=style&index=0&id=09079458&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "09079458",
  null
  
)

export default component.exports