var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"related-products spad"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-3 col-sm-6"},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"pi-pic"},[_c('img',{attrs:{"src":"img/products/women-1.jpg","alt":""}}),_c('ul',[_vm._m(1),_c('li',{staticClass:"quick-view"},[_c('router-link',{attrs:{"to":"/product"}},[_vm._v("+ Quick View")])],1)])]),_vm._m(2)])]),_vm._m(3),_vm._m(4),_vm._m(5)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"section-title"},[_c('h2',[_vm._v("Related Products")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('li',{staticClass:"w-icon active"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon_bag_alt"})])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"pi-text"},[_c('div',{staticClass:"catagory-name"},[_vm._v("Coat")]),_c('a',{attrs:{"href":"#"}},[_c('h5',[_vm._v("Pure Pineapple")])]),_c('div',{staticClass:"product-price"},[_vm._v(" $14.00 "),_c('span',[_vm._v("$35.00")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-3 col-sm-6"},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"pi-pic"},[_c('img',{attrs:{"src":"img/products/women-2.jpg","alt":""}}),_c('ul',[_c('li',{staticClass:"w-icon active"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon_bag_alt"})])]),_c('li',{staticClass:"quick-view"},[_c('a',{attrs:{"href":"#"}},[_vm._v("+ Quick View")])])])]),_c('div',{staticClass:"pi-text"},[_c('div',{staticClass:"catagory-name"},[_vm._v("Shoes")]),_c('a',{attrs:{"href":"#"}},[_c('h5',[_vm._v("Guangzhou sweater")])]),_c('div',{staticClass:"product-price"},[_vm._v(" $13.00 ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-3 col-sm-6"},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"pi-pic"},[_c('img',{attrs:{"src":"img/products/women-3.jpg","alt":""}}),_c('ul',[_c('li',{staticClass:"w-icon active"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon_bag_alt"})])]),_c('li',{staticClass:"quick-view"},[_c('a',{attrs:{"href":"#"}},[_vm._v("+ Quick View")])])])]),_c('div',{staticClass:"pi-text"},[_c('div',{staticClass:"catagory-name"},[_vm._v("Towel")]),_c('a',{attrs:{"href":"#"}},[_c('h5',[_vm._v("Pure Pineapple")])]),_c('div',{staticClass:"product-price"},[_vm._v(" $34.00 ")])])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"col-lg-3 col-sm-6"},[_c('div',{staticClass:"product-item"},[_c('div',{staticClass:"pi-pic"},[_c('img',{attrs:{"src":"img/products/women-4.jpg","alt":""}}),_c('ul',[_c('li',{staticClass:"w-icon active"},[_c('a',{attrs:{"href":"#"}},[_c('i',{staticClass:"icon_bag_alt"})])]),_c('li',{staticClass:"quick-view"},[_c('a',{attrs:{"href":"#"}},[_vm._v("+ Quick View")])])])]),_c('div',{staticClass:"pi-text"},[_c('div',{staticClass:"catagory-name"},[_vm._v("Towel")]),_c('a',{attrs:{"href":"#"}},[_c('h5',[_vm._v("Converse Shoes")])]),_c('div',{staticClass:"product-price"},[_vm._v(" $34.00 ")])])])])
}]

export { render, staticRenderFns }